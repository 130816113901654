
.columnsCustomizer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .tabBody {
    // background-color: white;
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
  
    .availableOptions {
      width: 100%;
      p {
        font-size: 1.3rem !important;
      }
      margin-bottom: 30px;
  
  
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  
      .optionsWrapper {
        border: 1px dashed grey;
        min-height: 100px;
        margin-top: 30px;
        padding: 15px 30px;
        width: 100%;
        align-items: center;
        display: flex;
  
        .option {
          background-color: white;
          min-width: 100px;
          font-size: 0.7rem;

          box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.15);
          cursor: grab;
          height: 40px;
          margin: 0 10px;
          padding: 5px 24px;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  
    .selectedOptions {
      p {
        font-size: 1.3rem !important;
      }
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  
      .optionsWrapper {
        min-height: 100px;
        border: 1px solid brown;
        background-color: brown;
        margin-top: 30px;
        padding: 15px 30px;
        width: 100%;
        align-items: center;
        display: flex;
  
        .option {
          background-color: white;
          min-width: 100px;
          font-size: 0.7rem;

          box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.15);
          cursor: grab;
          height: 40px;
          margin: 0 10px;
          padding: 5px 24px;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  } 
  .actionsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    .updateBtn {
      cursor: pointer;
      background-color: rgb(0, 218, 116);
      color: white;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 7px 55px;
      border: none;
      margin-right: 10px;

      border-radius: 18px;
    }
    .resetBtn {
      cursor: pointer;
      margin-left: 10px;
      background-color: rgb(216, 0, 0);
      color: white;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 7px 55px;
      border: none;
      border-radius: 18px;
    } 
  }

}