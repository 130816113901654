.patientVisitDetailsTable {

  .table-scroll {
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    margin-top: 23px;
    height: 350px;
  }
  .table-scroll table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }
  .table-wrap {
    position: relative;
  }
  .table-scroll th,
  .table-scroll td {
    padding: 5px 10px;
    /* border: 1px solid #000; */
    /* background: #fff; */
    text-align: center;
    vertical-align: top;
  }
  tbody tr:nth-child(even) {background-color: #d8d8d8!important;}
  .table-scroll thead th {
    background: #333;
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-left: 1px solid white;
  }
  /* safari and ios need the tfoot itself to be position:sticky also */
  .table-scroll tfoot,
  .table-scroll tfoot th,
  .table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #666;
    text-align: center;
    color: #fff;
    z-index:4;
  }
  
  a:focus {
    background: red;
  } /* testing links*/
  
  th:first-child {
    // position: -webkit-sticky;
    // position: sticky;
    // left: 0;
    // z-index: 2;
    // background: rgba(204, 204, 204, 0);
  }
  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }
}