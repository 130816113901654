@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');

$base-spacing-unit: 24px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772FF;
$color-form-highlight: #EEEEEE;


.customizeStateModalInnerWrapper {
    width: 100%;
    height: 100%;

    .loaderWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .csmTableWrapper {

        .modalActions {
            position: absolute;
            top: 3rem;
            right: 4rem;
        }

        padding: 1rem 3rem;

        h3 {
            // margin-top: -2rem;
            margin-top: 1rem;
            margin-bottom: 2.5rem;
        }

        *,
        *:before,
        *:after {
            box-sizing: border-box;
        }

        .container {
            max-width: 1000px;
            margin-right: auto;
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
        }

        .table {
            overflow-y: scroll;
            max-height: 70vh;
            width: 100%;
            border: 1px solid $color-form-highlight;
        }

        .table-header {
            display: flex;
            width: 100%;
            background: #000;
            padding: ($half-spacing-unit * 1.5) 0;
        }

        .table-row {
            display: flex;
            width: 100%;
            padding: ($half-spacing-unit * 1.5) 0;

            &:nth-of-type(odd) {
                background: $color-form-highlight;
            }
        }

        .table-data,
        .header__item {
            flex: 1 1 20%;
            text-align: center;
        }

        .header__item {
            text-transform: uppercase;
        }

        .table-data {
            input {}
        }

        .filter__link {
            color: white;
            text-decoration: none;
            position: relative;
            display: inline-block;
            padding-left: $base-spacing-unit;
            padding-right: $base-spacing-unit;

            &::after {
                content: '';
                position: absolute;
                right: -($half-spacing-unit * 1.5);
                color: white;
                font-size: $half-spacing-unit;
                top: 50%;
                transform: translateY(-50%);
            }

            &.desc::after {
                content: '(desc)';
            }

            &.asc::after {
                content: '(asc)';
            }

        }
    }
}