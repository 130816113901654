
.screenNavigatorWrapper {
  margin-top: 1rem;
  margin-bottom: 3rem;
  .screenNavigator {
    width: 480px;
    height: 60px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 7px 20px rgba(0,0,0,0.07);
    padding: 8px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .navItem {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 0.8rem;
      padding: 0 15px;
      color: rgb(158, 158, 158);
      cursor: pointer;
      // font-weight: 600;
      i {
        font-size: 0.9rem;
        padding-right: 7px;
      }
      &.active {
        color: rgb(180, 0, 0);
        i {
          font-size: 1.2rem!important;
        }
      }
      &:hover {
        color: rgb(206, 0, 0);
      }
    }
    .navSeparator {
      font-size: 0.8rem;
      padding: 0 15px;
      color: rgb(179, 179, 179);
    }
  }
}