.tvTableOrganizer {
  width: 100%;

  .option {
    cursor: pointer;
    border: 1px solid grey;
    margin-right: 8px;
    padding: 4px 24px;
    cursor: grab;
  }

  .tabOuterWrapper {
    width: 100%;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tabOptions {
      height: 40px;
      border-radius: 22px;
      background-color: rgb(219, 219, 219);
      width: 400px;
      display: flex;

      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        font-weight: 600;
        color: rgb(0, 0, 0);
        cursor: pointer;
        flex: 1;

        &.active {
          font-size: 0.9rem;
          color: white;
          background-color: rgb(0, 132, 255);
          border-radius: 26px;
        }
      }
    }

    .tabBody {
      // background-color: white;
      width: 70%;
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;

      .availableOptions {
        width: 100%;
        p {
          font-size: 1.3rem !important;
        }
        margin-bottom: 30px;


        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .optionsWrapper {
          border: 1px dashed grey;
          min-height: 100px;
          margin-top: 30px;
          padding: 15px 30px;
          width: 100%;
          align-items: center;
          display: flex;

          .option {
            background-color: white;
            box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.15);
            cursor: grab;
            min-width: 100px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .selectedOptions {
        p {
          font-size: 1.3rem !important;
        }
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .optionsWrapper {
          min-height: 100px;
          border: 1px solid brown;
          background-color: brown;
          margin-top: 30px;
          padding: 15px 30px;
          width: 100%;
          align-items: center;
          display: flex;

          .option {
            background-color: white;
            min-width: 100px;

            box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.15);
            cursor: grab;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .hide {
    display: none !important;
  }
}