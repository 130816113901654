.clinicCustomViewWrapper {
    margin-top: 6rem;
    padding: 2rem 3rem;
    min-height: 90vh;
    position: relative;
    // background-color: aqua;
    width: 100%;

    .intakeFormLink {
        position: absolute;
        top: -4.9rem;
        z-index: 99999;
        right: 12rem;

        button {
            background-color: white;
            border: none;
            border-radius: 5px;
            box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
            padding: 4px 15px;
            font-size: 1rem;
            // font-weight: 600;
            cursor: pointer;
            
            &:hover {
                background-color: rgb(226, 224, 224);
            }
        }
    }
}