.detailedStatsWrapper {
  display: flex;

  .detailedStats {
    width: calc(100% - 300px);

    .sectionHeader {
      background-color: #990000;
      color: white;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 6px;
      margin-left: 3px;
      font-weight: 600;
      // margin-top: 3rem;
      margin-bottom: 0rem;

      &.tableHeader {
        margin-bottom: -1.5rem;
      }
    }

    .csvDownloadPromptBtnWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 2rem;
      // margin-top: -1rem;

      .csvDownloadPromptBtn {
        border: none;
        border-radius: 1px;
        background-color: rgb(0, 184, 52);
        color: rgb(255, 255, 255);
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2);
        padding: 5px 35px;
        transition: 0.3s;
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: 600;

        i {
          margin-right: 12px;
        }

        &:hover {
          box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.05);
          background-color: rgb(1, 233, 67);

        }
      }
    }
  }
  .dashboardTimeRangePicker {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    padding-right: 2.7rem;
  }
}