#registrationSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  position: relative;
  min-height: 100vh;
  background-color: white;

  @media only screen and (max-height: 510px) {
    padding-top: 8rem;
  }

  h3 {
    text-align: center;
    // width:
    font-size: 3rem;
    @media only screen and (max-height: 510px) {
      font-size: 1.5rem;
    }
    @media only screen and (max-width: 450px) {
      font-size: 1.2rem;
      padding: 0 20px ;
    }

    span {
      font-size: 4rem;
      font-weight: 600;
    }
  }

  .progressWrapper {
    position: absolute;
    width: 100vw;
    bottom: 0;

    .progressbar-progress {
      background-color: #990000 !important;
    }
  }

  .resetBtn {
    background-color: #3f3f3f !important;
    padding: 16px 65px;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.17);
    font-size: 1.5rem;
    margin-top: 4rem;
    @media only screen and (max-height: 510px), screen and (max-width: 510px) {
      padding: 10px 45px;
      color: white;
      border: none;
      cursor: pointer;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.17);
      font-size: 0.8rem;
      margin-top: 1rem;
    }
  }

  .qrCode {
    // width: 200px;
    display: flex;
    align-items: center;
    p {
      margin-left: 1.8rem;
      font-size: 1.3rem;
    }
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}