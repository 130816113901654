.language-table {
  position: relative;
  width: 90%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 350px;

  .table-wrap {
    position: relative;
  }

  th,
  td {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    vertical-align: top;
    text-align: center;
  }

  thead th {
    background: #333;
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  /* safari and ios need the tfoot itself to be position:sticky also */
  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #666;
    color: #fff;
    z-index: 4;
  }

  a:focus {
    background: red;
  }

  /* testing links*/

  th:first-child {
    /* position: -webkit-sticky; */
    /* position: sticky; */
    left: 0;
    z-index: 2;
    background: #ccc;
  }

  
  thead th:first-child {
    background: #333 !important;
  }
  
  table {
    width: 90%;
    // min-width: 1280px;
    margin: auto;
    margin-top: 3rem;
    border-collapse: separate;
    border-spacing: 0;
  }
}