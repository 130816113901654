.progressBarWrapper {
  padding-top: 8rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -10rem;

  .stepLabel {
    margin-top: 45px;
    font-weight: 500;
    font-size: 1.5rem;
  }

  width: 100%;

  $inactiveColor: #dddddd;
  $activeColor: #cc0303;
  $barHeight: 8px;
  $ballRadius: 40px;

  .timeline {
    border-radius: 25px;
    width: 50%;
    @media only screen and (max-width: 450px) {
      width: 93%;
    }
    margin: auto;
    position: relative;
    background-color: $inactiveColor;
    height: $barHeight !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    padding: 0px;
  }

  .timeline__progress {
    min-width: 15px;
    z-index: 1;
    border-radius: 500px;
    position: absolute;
    background-color: $activeColor;
    height: $barHeight;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.2s ease;
  }

  .timeline__ball {
    z-index: 2;
    background-color: $inactiveColor;
    height: $ballRadius;
    width: $ballRadius;
    border: 4px solid $inactiveColor;
    background-color: white;
    border-radius: 100px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      border-color: $activeColor;
    }
  }
}