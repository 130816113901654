.flowCustomizerWrapper {
  height: 80vh;
  margin-top: 2rem;
  display: flex;
  margin-bottom: -20rem;
  $sidebarWidth: 290px;

  .sideBar {
    width: $sidebarWidth;
    background-color: rgb(243, 243, 243);

    .sidebarHeader {
      width: 100%;
      background-color: darkred;
      color: white;
      padding: 12px 25px;
      text-align: center;
      font-weight: 600;
    }

    .sidebarBody {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 60vh;
      overflow-y: scroll;
      .option {
        background-color: rgb(255, 255, 255);
        margin-top: 15px;
        border: 1px solid black;
        // cursor: grab;
        height: 40px;
        padding: 6px 5px 6px 15px;
        border-radius: 12px;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {

        }
        .addBtn {
          width: 25px;
          cursor: pointer;
        }
      }
    }

    position: relative;

    .actionBar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 280px;
      min-height: 90px;
      background-color: rgb(12, 12, 12);
      .nodeAction {
        display: flex;
        align-items: center;
        padding: 15px 6px 15px 25px;
        color: white!important;
        .labelPart {
          flex: 10;
          .label {
            font-size: 0.8rem;
            font-weight: 600;
          }
          .value {
            font-size: 1rem;
          }
        }
        .deleteBtnWrapper {
          flex: 3;
          button {
            border-radius: 30px;
            background-color: red;
            border: none;
            cursor: pointer;
            padding: 10px 16px;
            cursor: pointer;
            box-shadow: 2px 2px 15px rgba(255, 38, 38, 0.253);
            color: white;
          }
        }
      }
    }
  }

  .dragDropCustomizerWrapper {
    width: calc(100% - #{$sidebarWidth});

    // width: 600px;
    &>div {
      height: 100%;
    }
  }

  .customNode {
    padding: 22px 55px 15px 55px;
    font-weight: 600;
    text-align: center;
  }

  .advancedActionsWrapper {
    margin-top: 80px;
    position: absolute;
    top: 10px;
    left: 15px;
    button {
      border: none;
      outline: none;
      cursor: pointer;
      z-index: 999999;
      color: rgb(124, 124, 124);
      &:hover {
        color: rgb(39, 39, 39)!important;
      }
    }
  }

  .editorActions {
    display: flex;
    position: absolute;
    top: 10px;
    right: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    .updateBtn {
      cursor: pointer;
      background-color: rgb(0, 218, 116);
      color: white;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 7px 55px;
      border: none;
      margin-right: 10px;

      border-radius: 18px;
    }

    .resetBtn {
      cursor: pointer;
      margin-left: 10px;
      background-color: rgb(216, 0, 0);
      color: white;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 7px 55px;
      border: none;
      border-radius: 18px;
    }
  }
}