.time-range-picker-wrapper {
  display: flex;
  .resetTimeRange {
    background-color: transparent;
    border: none;
    font-size: 13px;
    cursor: pointer;
    padding-left: 15px;
    outline: none;
  }  
}