.manageAppsPageWrapper {
  min-height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .appsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .appWrapper {
      width: 350px;
      margin: 10px 15px;
      position: relative;
      height: 170px;
      border-radius: 4px;
      border: 1px solid rgb(155, 0, 0);
      background-color: white;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.09);

      .switchBtn {
        position: absolute;
        top: 10px;
        right: 15px;
      }

      .appBody {
        width: 100%;
        height: 80%;
        display: flex;
        padding-top: 15px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .fas {
          font-size: 2rem;
        }

        p {
          margin-top: 9px;
          font-size: 1.2rem;
          font-weight: 600;
        }
      }

      .appFooter {
        width: 100%;
        height: 20%;

        .enabled {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0;
          background-color: rgb(0, 194, 0);
        }
      }
    }
  }

  .switchBtn {}

  /***/

  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+.tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    +.tgl-btn {
      outline: 0;
      display: block;
      width: 3em;
      height: 1.5em;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked+.tgl-btn:after {
      left: 50%;
    }
  }

  // themes
  .tgl-light {
    +.tgl-btn {
      background: #b6b6b6;
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;

      &:after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
      }
    }

    &:checked+.tgl-btn {
      background: #029e2c;
    }
  }

  /***/
}